import React from "react"
import Helmet from "react-helmet"
import styled from "styled-components"

import Header from "../components/Header"
import Footer from "../components/Footer"

import Theme from "../components/Theme"

const Section = styled.div`
  margin-top: 64px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  padding: 0 40px;
  justify-content: space-around;
  max-width: 1080px;
  flex-direction: column;
  @media screen and (min-width: 600px) {
    flex-direction: row;
  }
  min-height: 100vh;
`

const Text = styled.div`
  flex: 1;
  strong {
    color: #fa824c;
    font-weight: normal;
  }
  margin-bottom: 16px;
`

const SectionTitle = styled.h2`
  margin-top: 0;
`

const LegalPage = () => {
  return (
    <Theme>
      <Helmet>
        <meta charSet="utf-8" />
        <title defer={false}>Pharmille - Mentions légales</title>
        <meta
          name="description"
          content="Pharmille simplifie la gestion du personnel de pharmacie."
        />
        <meta name="robots" content="noindex, follow" />
      </Helmet>
      <Header bg="#08415C" />
      <Section>
        <Text>
          <SectionTitle>Mentions Légales</SectionTitle>
          Le site www.pharmille.com est édité par Clément Patout - Maulbronner
          Ufer 30, 12247 Berlin. <br />
          <br />
          Ce site est hébergé par la société Netlify, dont le siège se situe au 2325 3rd St #215, San Francisco CA 94107 (USA). (www.netlify.com)
          <br />
          <br />
          Clément Patout est responsable de la publication, pour toute
          information,{" "}
          <a href="mailto:contact@pharmille.com">
            contactez nous par mail ici.
          </a>
        </Text>
      </Section>
      <Footer />
    </Theme>
  )
}

export default LegalPage
